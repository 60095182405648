
import { TagEnums } from '@/utils/tag/constants.js';

import Tag from '@/components/common/tag/Tag.vue';

export default {
  name: 'TeacherTagList',
  components: { Tag },
  props: {
    tags: { type: Array, default: () => [] },
  },
  data: () => {
    return {
      TagEnums,
    };
  },
};
