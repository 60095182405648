
/**
 * @description
 * 홈 > 선생님 큐레이션의 아이템
 */

import TeacherTagList from './TeacherTagList.vue';
import TeacherProfileImg from './TeacherProfileImg.vue';

export default {
  name: 'TeacherItem',
  components: { TeacherTagList, TeacherProfileImg },
  props: {
    name: { type: String, default: null },
    thumbnailImg: { type: String, default: null },
    curationTitle: { type: String, default: null },
    tags: { type: Array, default: () => [] },
  },
};
