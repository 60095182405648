import { render, staticRenderFns } from "./TeacherTagList.vue?vue&type=template&id=00d4ddf9&scoped=true"
import script from "./TeacherTagList.vue?vue&type=script&lang=js"
export * from "./TeacherTagList.vue?vue&type=script&lang=js"
import style0 from "./TeacherTagList.vue?vue&type=style&index=0&id=00d4ddf9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d4ddf9",
  null
  
)

export default component.exports