
/**
 * @description
 * 홈 > 선생님 큐레이션
 */

import { HOME_TYPE } from '@/utils/amplitudeEvent/amplitudeParams.js';
import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeEvent/amplitudeSectionNames.js';
import { CLICK_TEACHER_CURATION } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { getPathAt } from '@/utils/path';

import Swiper from '@/components/common/swiper/CssSwiper.vue';
import TeacherCurationSkeleton from './TeacherCurationSkeleton.vue';
import TeacherItem from './TeacherItem.vue';

export default {
  name: 'TeacherCuration',
  components: { Swiper, TeacherItem, TeacherCurationSkeleton },
  props: {
    title: { type: String, default: null },
    teacherList: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
    isTalent: { type: Boolean, default: false },
  },
  computed: {
    bestTeacherSectionName: () => NEW_HOME_SECTION_NAME.BEST_TEACHER,
  },
  methods: {
    logClickTeacher({ teacherId, idx }) {
      this.logUserCustomEvent(CLICK_TEACHER_CURATION, {
        teacher_id: teacherId,
        section: this.bestTeacherSectionName,
        sequence: idx + 1,
        at: getPathAt(this.$route),
        home_type: this.isTalent ? HOME_TYPE.TALENT : HOME_TYPE.EDUCATION,
      });
    },
  },
};
