
/**
 * @description
 * 선생님 프로필 이미지
 */

export default {
  name: 'TeacherProfileImg',
  props: {
    teacherProfileImg: { type: String, default: null },
  },
  data() {
    return {
      isError: false,
    };
  },
  computed: {
    profileImg: ({ teacherProfileImg, $imgWrapper }) =>
      $imgWrapper(teacherProfileImg, 300),
  },
  methods: {
    onImageLoadFail(event) {
      if (this.isError) return;

      this.isError = true;
      event.target.src = '#';
    },
  },
};
